import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const NavbarWrapper = styled.nav`
  width: 100%;
  padding: 27px 0;
  z-index: 9999;
  transition: all 0.3s ease;
  .main-logo {
    flex-shrink: 0;
    img {
      min-width: 120px;
      max-width: 145px;
      height: auto;
    }
  }
  > div.container {
    display: flex;
    align-items: center;
  }
  ul {
    li {
      a {
        color: ${themeGet("colors.menu", "#02073E")};
        font-size: 15px;
        font-weight: normal;
        transition: all 0.3s ease;
        &:hover {
          color: ${themeGet("colors.primary", "#6C247E")};
        }
      }
      &.is-current {
        a {
          color: ${themeGet("colors.primary", "#6C247E")};
        }
      }
    }
  }
`;

export const MenuArea = styled.div`
  display: flex;
  align-items: center;
  margin-left: 40px;
  .menu {
    display: flex;
    align-items: center;
    margin-right: 11px;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease;
  }
  &.active {
    .menu {
      opacity: 0;
      visibility: hidden;
    }
  }
  .reusecore__button {
    border-radius: 5px;
    font-weight: 500;
    text-transform: inherit;
    padding-left: 13px;
    padding-right: 13px;
    min-height: 42px;

    &.text {
      padding: 0;
      margin-right: 28px;
      cursor: pointer;
      .btn-icon {
        svg {
          width: 22px;
          height: auto;
          stroke: ${themeGet("colors.menu", "0D233E")};
          @media only screen and (max-width: 991px) {
            width: 24px;
          }
        }
      }
      @media only screen and (max-width: 1366px) {
        margin-right: 20px;
      }
      @media only screen and (max-width: 991px) {
        margin-right: 0;
      }
    }
    &.trail {
      &:hover {
        box-shadow: #d1397c 0px 12px 24px -10px;
      }
      @media only screen and (max-width: 991px) {
        display: none;
      }
    }
    &.menubar {
      display: none;
      @media only screen and (max-width: 991px) {
        display: inline-flex;
        padding: 0;
        justify-content: flex-end;
        min-width: 35px;
        color: ${themeGet("colors.primary", "#6C247E")};
        svg {
          width: 27px;
          height: auto;
        }
      }
    }
  }
`;

export default NavbarWrapper;
