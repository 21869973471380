import React from "react";
import { ThemeProvider } from "styled-components";
import { theme } from "common/theme/business";
import { ResetCSS } from "common/assets/css/style";
import CountdownNavbar from "containers/Market/Navbar";
import Container from "common/components/UI/Container";
import Fade from "react-reveal/Fade";
import Heading from "common/components/Heading";
import Text from "common/components/Text";
import Seo from "components/seo";
import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from "containers/Business/business.style";
import "@redq/reuse-modal/es/index.css";

import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { rgba } from "polished";
import moment from "moment";

import Countdown from "react-countdown";

const releaseDate = moment("2021-11-01 21:00:00");

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <PageNotFound />;
  } else {
    return (
      <ThemeProvider theme={theme}>
        <>
          <Seo title="Mercadia" />
          <ResetCSS />
          <GlobalStyle />

          <AppWrapper>
            <CountdownNavbar />

            <ContentWrapper>
              <BannerWrapper>
                <Container>
                  <BannerContent>
                    <Fade up delay={100}>
                      {/* — */}
                      <Heading
                        as="h1"
                        content="Delivery de comida y mercado 🖐️"
                      />

                      <Text
                        content={`Lanzamiento — ${moment(releaseDate).format(
                          "D MMM. YYYY — hh:mma"
                        )}`}
                      />

                      <Heading
                        as="h2"
                        content={`🔥 ${days} días - ${dateFormat(
                          hours
                        )}:${dateFormat(minutes)}:${dateFormat(seconds)}`}
                      />

                      <Space />

                      <a
                        href="https://play.google.com/store/apps/details?id=co.mercadia.android"
                        target="_blank"
                      >
                        Descargar versión preliminar
                      </a>
                    </Fade>
                  </BannerContent>
                </Container>
              </BannerWrapper>
            </ContentWrapper>
          </AppWrapper>
        </>
      </ThemeProvider>
    );
  }
};

function dateFormat(value) {
  if (value < 10) return "0" + value;
  return value;
}

// const CountDown = () => {
//   return <Countdown date={releaseDate} renderer={renderer} />;
// };

const CountDown = () => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <Seo title="Mercadia" />
        <ResetCSS />
        <GlobalStyle />

        <AppWrapper>
          <CountdownNavbar />

          <ContentWrapper>
            <BannerWrapper>
              <Container>
                <BannerContent>
                  <Fade up delay={100}>
                    {/* — */}
                    <Heading
                      as="h1"
                      content="Delivery de comida y mercado 🖐️"
                    />

                    <Text
                      content={`Lanzamiento — ${moment(releaseDate).format(
                        "D MMM. YYYY"
                      )}`}
                    />

                    <Heading as="h2" content={`🔥 0 días - 00:00:00`} />

                    <Space />

                    <a
                      href="https://play.google.com/store/apps/details?id=co.mercadia.android"
                      target="_blank"
                    >
                      Descargar
                    </a>
                  </Fade>
                </BannerContent>
              </Container>
            </BannerWrapper>
          </ContentWrapper>
        </AppWrapper>
      </>
    </ThemeProvider>
  );
};

const PageNotFound = () => {
  return (
    <main style={pageStyles}>
      <title>Not found</title>
      <h1 style={headingStyles}>Page not found</h1>
      <p style={paragraphStyles}>
        Sorry{" "}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{" "}
        we couldn’t find what you were looking for.
        <br />
      </p>
    </main>
  );
};

export default CountDown;

const BannerWrapper = styled.div`
  padding-top: 0px;
  overflow: hidden;
  position: relative;

  .TrustedImageBtn {
    padding-top: 30px;
    display: flex;
    img {
      width: 150px;
      height: 47px;
    }
    .app_image_area {
      margin-right: 20px;
    }
  }

  > div.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: calc(850px - 80px);
    @media only screen and (max-width: 1199px) {
      padding-top: 50px;
      padding-bottom: 0px;
      min-height: auto;
    }
    @media only screen and (max-width: 991px) {
      padding-top: 50px;
      padding-bottom: 5px;
    }
    @media only screen and (max-width: 768px) {
      padding-bottom: 10px;
    }
  }
`;

const Space = styled.div`
  width: 100%;
  height: 100px;
`;

const BannerContent = styled.div`
  width: 100%;
  text-align: center;
  flex-shrink: 0;
  margin-top: -70px;
  @media only screen and (max-width: 1219px) {
    flex-shrink: 0;
    margin-top: 0px;
  }
  h1 {
    font-size: 72px;
    line-height: 1.25;
    font-weight: 700;
    color: ${themeGet("colors.headingColor", "#0F2137")};
    margin-bottom: 20px;
    letter-spacing: -2px;
    @media only screen and (max-width: 1366px) {
      font-size: 52px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 40px;
    }
    @media only screen and (max-width: 767px) {
      font-size: 36px;
      line-height: 1.35;
    }
    @media only screen and (max-width: 480px) {
      font-size: 28px;
    }
  }

  p {
    font-size: 20px;
    line-height: 2;
    color: ${rgba("#02073e", 0.8)};
    @media only screen and (max-width: 1366px) {
      font-size: 18px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 16px;
      line-height: 1.8;
    }
  }
`;

// styles
const pageStyles = {
  color: "#232129",
  textAlign: "center",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};

const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
};

const paragraphStyles = {
  marginBottom: 48,
};
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
};
