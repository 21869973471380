import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Logo from "common/components/UIElements/Logo";
import NavbarWrapper, { MenuArea } from "./navbar.style";
import styled from "styled-components";
import LogoImage from "common/assets/image/market/logo-market.svg";

const CountdownNavbar = () => {
  const data = useStaticQuery(graphql`
    query {
      businessJson {
        navbar {
          logo {
            publicURL
          }
        }
      }
    }
  `);

  const { logo, navMenu } = data.businessJson.navbar;

  return (
    <NavbarWrapper className="navbar">
      <Container>
        <Logo
          href="/"
          logoSrc={LogoImage}
          title="Marcadia"
          className="main-logo"
        />

        <MenuArea>
          <Link to="/negocios">Negocios</Link>
        </MenuArea>
      </Container>
    </NavbarWrapper>
  );
};

const Container = styled.nav`
  width: 100%;
  padding: 27px 0;
  align-items: center;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
`;

export default CountdownNavbar;
